import React from "react"
import Helmet from "react-helmet"
import Header from "../components/layouts/header"
import css from "@emotion/css"
import { graphql } from "gatsby"
function Index({ data }) {
  const {
    pageTitle,
    metaDescription,
    contentfulGenericPage: { pageContent },
  } = data

  return (
    <>
    <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content="Ratio, fintech, finance, software" />
    </Helmet>
      <div>
        <div className="hero bg-gradient">
          {" "}
          <Header />
        </div>
      </div>
      <TextSection html={pageContent.md.html} />
    </>
  )
}

export default Index

export const query = graphql`
  query($slug: String) {
    contentfulGenericPage(slug: { eq: $slug }) {
      pageTitle: title
      metaDescription
      pageContent {
        md: childMarkdownRemark {
          html
        }
      }
    }
  }
`

const TextSection = ({ html, aCSS }) => (
  <section className="section-py">
    <div
      className="container "
      css={css`
        ${aCSS}
      `}
    >
      {" "}
      <div className="row">
        <div className="col-12 col-md-10 col-lg-10 offset-0 offset-md-1">
          <div
            id="aboutContentTop"
            css={css`
              h1,
              h2 {
                font-family: "futura-pt", sans-serif;
                font-weight: 600;

                margin-bottom: 2rem;
                color: var(--primary);

                @media (min-width: 992px) {
                  max-width: 60%;
                }
              }
              h2 {
                font-size: 36px;
              }
              h3 {
                font-size: 28px;
              }
            `}
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
        </div>
      </div>
    </div>
  </section>
)
