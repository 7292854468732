import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import css from "@emotion/css";

import { Collapse } from "reactstrap";

export default function Header({ light }) {
  const [open, setOpen] = useState(false);
  const MenuRef = useRef(null);
  function close(e) {
    if (MenuRef.current && !MenuRef.current.contains(e.target)) {
      setOpen(false);
    }
  }
  useEffect(() => {
    document.addEventListener("click", close);
    return () => {
      document.removeEventListener("click", close);
    };
  }, [open]);
  return (
    <>
      <header
        css={css`
          padding-top: 1rem;
          padding-bottom: 1rem;

          @media (min-width: 992px) {
            padding-top: 2rem;
            padding-bottom: 2rem;
          }
          @media (min-width: 1200px) {
            padding-top: 3rem;
            padding-bottom: 3rem;
          }
        `}
      >
        <div className="container d-flex align-items-center justify-content-between">
          <div id="logo">
            <Link to="/">
              <img
                css={css`
                  max-width: 100px;
                  width: 100px;
                  height: 29.5px;
                  transition-duration: 0.6s;
                  transition-property: transform;
                  &:hover {
                    transform: rotate(-4deg);
                  }
                `}
                alt="Ratio logo"
                src={light ? "/images/logo-white.svg" : "/images/logo.svg"}
              />
            </Link>
          </div>
          <div
            className="d-block d-md-none font-weight-bold"
            onClick={() => {
              setOpen(o => !o);
            }}
            css={css`
              cursor: pointer;
            `}
          >
            <span
              id=""
              css={css`
                svg {
                  fill: var(--dark);
                  &:hover {
                    fill: var(--white);
                    text-decoration: none;
                  }
                }
              `}
            >
              <svg viewBox="0 0 24 24" width="32" height="32">
                <path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z" />
              </svg>
            </span>
          </div>

          <div className="d-md-block d-none">
            {" "}
            {[
              { label: "About", link: "/about/" },
              { label: "Products", link: "/products/" },
              { label: "Careers", link: "/careers/" },
              { label: "Investors", link: "/investors/" },
              { label: "Contact", link: "/contact/" }
            ].map(item => {
              return (
                <Link
                  activeClassName="active"
                  css={css`
                    margin-left: 40px;
                    color: #1d1d1d;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 22px;
                    &:hover {
                      color: ${light ? "var(--primary)" : "#fff"};
                      text-decoration: none;
                    }
                    &.active {
                      color: ${light ? "var(--primary)" : "#fff"};
                    }
                  `}
                  partiallyActive={true}
                  key={item.link}
                  to={item.link}
                >
                  {item.label}
                </Link>
              );
            })}{" "}
          </div>
        </div>
        <Collapse isOpen={open}>
          <div className="container mt-3 d-flex flex-column " ref={MenuRef}>
            {" "}
            {[
              { label: "About", link: "/about/" },
              { label: "Products", link: "/products/" },
              { label: "Careers", link: "/careers/" },
              { label: "Investors", link: "/investors/" },
              { label: "Contact", link: "/contact/" }
            ].map(item => {
              return (
                <Link
                  activeClassName="active"
                  css={css`
                    color: var(--dark);
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 22px;
                    margin-top: 1rem;
                    text-align: center;
                    &:hover,
                    &.active {
                      color: ${light ? "var(--primary)" : "#fff"};
                    }
                  `}
                  partiallyActive={true}
                  key={item.link}
                  to={item.link}
                >
                  {item.label}
                </Link>
              );
            })}{" "}
          </div>
        </Collapse>
      </header>
    </>
  );
}
